import { Language } from './go-localize-helper.service';

// NOTE:
// This is being duplicated in `wrapper.module.ts` in video-sharing. If you make changes here,
// please update over there as well.

/** @ngInject */
export const translateConfig = (
	$translateProvider: ng.translate.ITranslateProvider,
	$translatePartialLoaderProvider: ng.translate.ITranslatePartialLoaderProvider,
	tmhDynamicLocaleProvider: tmh.tmh.IDynamicLocaleProvider
) => {

	$translateProvider.useLoader('$translatePartialLoader', {
		urlTemplate: 'https://staticassets.goreact.com/prod-client/app/translations/translation/{lang}{part}?v=1724774036592'
	});

	tmhDynamicLocaleProvider.localeLocationPattern('https://staticassets.goreact.com/prod-client/app/translations/locales/locale-{{locale}}.js');
	tmhDynamicLocaleProvider.defaultLocale(Language.EN);

	// This ensures the "main" json file is loaded immediately
	$translatePartialLoaderProvider.addPart('.json');

	$translateProvider.uniformLanguageTag('bcp47');
	$translateProvider.useSanitizeValueStrategy('escapeParameters');
	$translateProvider.preferredLanguage('en');
	$translateProvider.fallbackLanguage('en');
	$translateProvider.useMissingTranslationHandler('goreactMissingTranslationHandler');
};
